<template>
	<!-- 智能控制 -->
	<div class="agricultural_optimization">
		<!-- 头部 -->
		<div class="land_head">
			<!-- 大棚选择器 -->
			<div class="select">
					<el-select v-model="value1" clearable  placeholder="请选择" @change="typeChange">
					    <el-option
					      v-for="item in options1"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					</el-select>
					<el-select v-model="value2" clearable  placeholder="请选择" @change="landChange">
					    <el-option
					      v-for="item in options2"
					      :key="item.id"
					      :label="item.yard_name"
					      :value="item.id">
					    </el-option>
					</el-select>
			</div>
			<!-- <div class="land_title">农业用地数据监测</div> -->
			<div class="back" @click="back">
				<i class="el-icon-back"></i>
				<span style="margin-left: 4px;">返回</span>
			</div>
		</div>
		<!-- 中间部分 -->
		<div class="land_middle">
			<div class="land_pic">
				<img src="../../assets/map/no_1.jpg" v-if="landId===1"/>
				<img src="../../assets/map/no_2.jpg" v-if="landId===2"/>
				<img src="../../assets/map/no_3.jpg" v-if="landId===3"/>
				<img src="../../assets/map/no_4.jpg" v-if="landId===4"/>
				<img src="../../assets/map/no_5.jpg" v-if="landId===5"/>
				<img src="../../assets/map/no_6.jpg" v-if="landId===6"/>
				<img src="../../assets/map/no_7.jpg" v-if="landId===7"/>
				<img src="../../assets/map/no_8.jpg" v-if="landId===8"/>
				<img src="../../assets/map/no_9.jpg" v-if="landId===9"/>
				<img src="../../assets/map/no_10.jpg" v-if="landId===10"/>
				<img src="../../assets/map/no_11.jpg" v-if="landId===11"/>
				<img src="../../assets/map/no_12.jpg" v-if="landId===12"/>
				<img src="../../assets/map/no_13.jpg" v-if="landId===13"/>
				<img src="../../assets/map/no_14.jpg" v-if="landId===14"/>
				<img src="../../assets/map/no_15.jpg" v-if="landId===15"/>
				<img src="../../assets/map/no_16.jpg" v-if="landId===16"/>
				<img src="../../assets/map/no_17.jpg" v-if="landId===17"/>
			</div>
			<!-- 灌溉与施肥次数监测 -->
			<div class="video">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>灌溉与施肥次数监测</div>
						<div class="line"></div>
					</div>
				</div>
				<div id="myChart_a_optimization" ref='chart'></div>
			</div>
			<!-- 设备远程控制 -->
			<div class="sensor">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>设备远程控制</div>
						<div class="line"></div>
					</div>
				</div>
				<div class="sensor_list">
					<div class="sensor_item" v-for="(item,index) in sensorList" :key='index' v-if="sensorList.length>0">
						<div>{{item.device_name}}</div>
						<div>{{item.device_model}}</div>
						<!-- <div :class="item.status=='1'?'blue':'yellow'">{{item.status=='1'?'运行中':'离线'}}</div> -->
						<div :class="item.device_status=='1'?'blue':'yellow'">{{item.device_status=='1'?'运行中':'离线'}}</div>
						<div class="check" @click="check(item)">查看</div>
						<div class="check yellow2" @click="control(item)">远程控制</div>
					</div>
					<div v-if="sensorList.length==0" class="none">暂无传感设备</div>
				</div>
				
			</div>
		</div>
		<!-- 底部 -->
		<div class="land_bottom">
			<!-- 传感器数据监测 -->
			<div class="real_time">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>传感器数据监测</div>
						<div class="line"></div>
					</div>
				</div>
				<div class="real_list">
					<div v-for="(item_label,index_label) in labelList" :key='index_label' class="data_item">
					   <div>{{item_label}}<i></i></div>
					   <span class="data_number" v-if="item_label=='空气温度'">{{realObj.kqwd}}°C</span>
					   <span class="data_number" v-if="item_label=='空气湿度'">{{realObj.kqsd}}%</span>
					   <span class="data_number" v-if="item_label=='10cm土壤温度'">{{realObj.trwd10}}°C</span>
					   <span class="data_number" v-if="item_label=='10cm土壤湿度'">{{realObj.trsd10}}%</span>
					   <span class="data_number" v-if="item_label=='20cm土壤温度'">{{realObj.trwd20}}°C</span>
					   <span class="data_number" v-if="item_label=='20cm土壤湿度'">{{realObj.trsd20}}%</span>
					   <span class="data_number" v-if="item_label=='30cm土壤温度'">{{realObj.trwd30}}°C</span>
					   <span class="data_number" v-if="item_label=='30cm土壤湿度'">{{realObj.trsd30}}%</span>
					   <span class="data_number" v-if="item_label=='40cm土壤温度'">{{realObj.trwd40}}°C</span>
					   <span class="data_number" v-if="item_label=='40cm土壤湿度'">{{realObj.trsd40}}%</span>
					   <span class="data_number" v-if="item_label=='60cm土壤温度'">{{realObj.trwd60}}°C</span>
					   <span class="data_number" v-if="item_label=='60cm土壤湿度'">{{realObj.trsd60}}%</span>
					   <span class="data_number" v-if="item_label=='80cm土壤温度'">{{realObj.trwd80}}°C</span>
					   <span class="data_number" v-if="item_label=='80cm土壤湿度'">{{realObj.trsd80}}%</span>
					   <span class="data_number" v-if="item_label=='光合有效辐射'">{{realObj.gh==='null'?0:realObj.gh}}LUX</span>
					   <span class="data_number" v-if="item_label=='二氧化碳浓度'">{{realObj['2y']}}%</span>
					   <span class="data_number" v-if="item_label=='营养液EC值'">{{realObj.ec}}mS/cm</span>
					   <span class="data_number" v-if="item_label=='营养液pH值'">{{realObj.ph}}</span>
					   <span class="data_number" v-if="item_label=='灌溉量'">{{realObj.ggl}}L</span>
					   <span class="data_number" v-if="item_label=='分区灌溉'">{{realObj.fq_gg?realObj.fq_gg:0}}次</span>
					   <span class="data_number" v-if="item_label=='日灌溉'">{{realObj.day_gg?realObj.day_gg:0}}次</span>
					   <span class="data_number" v-if="item_label=='周灌溉'">{{realObj.week_gg?realObj.week_gg:0}}次</span>
					   <span class="data_number" v-if="item_label=='最近灌溉时间'">{{realObj.gg_time}}</span>
					   <span class="data_number" v-if="item_label=='风速'">{{realObj.fs}}m/s</span>
					   <span class="data_number" v-if="item_label=='风向'">{{realObj.fx}}°</span>
					   <span class="data_number" v-if="item_label=='光照强度'">{{realObj.gz}}KLux</span>
					   <span class="data_number" v-if="item_label=='雨量'">{{realObj.yuliang}}mm</span>
					   <span class="data_number" v-if="item_label=='大气压'">{{realObj.dqy}}Kpa</span>
					   <span class="data_number" v-if="item_label=='噪声'">{{realObj.zs}}db</span>
					   <span class="data_number" v-if="item_label=='PM 2.5'">{{realObj.pm2}}ug/m³</span>
					   <span class="data_number" v-if="item_label=='PM 10'">{{realObj.pm10}}ug/m³</span>
					</div>
				</div>
			</div>
			<!-- 传感设备运行状态 -->
			<div class="sensor2">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>传感设备运行状态</div>
						<div class="line"></div>
					</div>
				</div>
				<div class="sensor_list">
					<div class="sensor_item" v-for="(item,index) in sensorList" :key='index' v-if="sensorList.length>0">
						<div>{{item.device_name}}</div>
						<div>{{item.device_model}}</div>
						<div :class="item.device_status=='1'?'blue':'yellow'">{{item.device_status=='1'?'运行中':'离线'}}</div>
						<div class="check" @click="check(item)">查看详情</div>
					</div>
					<div v-if="sensorList.length==0" class="none">暂无传感设备</div>
				</div>
			</div>
			<!-- 灌溉调控设备参数 -->
			<div class="ggsb">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>灌溉调控设备参数</div>
						<div class="line"></div>
					</div>
				</div>
				<div class="sensor_list">
					<!-- <div class="sensor_item" v-for="(item,index) in ggList" :key='index'>
						<div>{{item.name}}</div>
						<div>{{item.type}}</div>
					</div> -->
					<div class="sensor_item">
						<div>营养液EC值</div>
						<div>{{ggList.ec}} mS/cm</div>
						<!-- <div class="check">配置参数</div> -->
					</div>
					<div class="sensor_item">
						<div>营养液pH值</div>
						<div>{{ggList.ph}}</div>
						<!-- <div class="check">配置参数</div> -->
					</div>
					<div class="sensor_item">
						<div>灌溉量</div>
						<div>{{ggList.ggl}} L</div>
						<!-- <div class="check">配置参数</div> -->
					</div>
					<div class="sensor_item">
						<div>分区灌溉</div>
						<div>{{ggList.fqgg}} 次</div>
						<!-- <div class="check">配置参数</div> -->
					</div>
					<div class="sensor_item">
						<div>日灌溉</div>
						<div>{{ggList.fqgg}} 次</div>
						<!-- <div class="check">配置参数</div> -->
					</div>
					<div class="sensor_item">
						<div>周灌溉</div>
						<div>{{ggList.week_gg}} 次</div>
						<!-- <div class="check">配置参数</div> -->
					</div>
					<div class="sensor_item">
						<div>最近灌溉时间</div>
						<div>{{timeFormate(ggList.gg_time)}}</div>
						<!-- <div class="check">配置参数</div> -->
					</div>
				</div>
			</div>
			<!-- 预警信息 -->
			<div class="ny_data">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>预警信息</div>
						<div class="line"></div>
					</div>
				</div>
				<div class="ny_list">
					<div v-for="(item,index) in warnList" :key='index' class="warn_item">
					  <span :class="item.level==2?'red':'quan'"></span>
					  <!-- <span></span> -->
					  <span>{{timeFormate(item.warn_time)}} {{item.warn_content}}</span>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { sendMessageWebsocket } from '@/api/websocket'
import { getYardList,realtimeDeviceMonitor,getNyDeviceList,irrigation_Regulation,irrigation_monitor,getWarningList} from '@/api/index'
// 引入所有 ECharts 中所有的图表和组件
import * as echarts from 'echarts';
export default {
  name: 'AgriculturalOptimization',
  data(){
	  return{
		 options1:[
		 		{ value: '1',
		 		  label: '日光棚'
		 		}, {
		 		  value: '2',
		 		  label: '春秋棚'
		 		}, {
		 		  value: '3',
		 		  label: '大田'
		 		}                 
		 ],
		  value1:'',
		  value2:'',
		  options2:[],
		 sensorList:[
			 {name:'水肥一体化调控设备',type:'SGH-12',status:1},
			 {name:'温室环境感知系统',type:'SGH-EMS',status:1},
			 {name:'无线温湿度传感器',type:'PG3H-30(H)',status:1},
			 {name:'3深度土壤墒情2',type:'PG3H-50B',status:1},
			 {name:'3深度土壤墒情3',type:'PG3H-50',status:1},
			 {name:'3深度土壤墒情4',type:'PG3H-50',status:1},
			 {name:'3深度土壤墒情5',type:'PG3H-50',status:1},
			 {name:'3深度土壤墒情6',type:'PG3H-50',status:1},
			 {name:'3深度土壤墒情7',type:'PG3H-50',status:0}
		 ],
		 ggList:[],
		 labelList:['空气温度','空气湿度','10cm土壤湿度','20cm土壤温度','20cm土壤湿度','30cm土壤湿度','光合有效辐射','二氧化碳浓度','营养液EC值','营养液pH值','灌溉量','分区灌溉','日灌溉','周灌溉','最近灌溉时间','光照强度'],
		 labelList1:['空气温度','空气湿度','10cm土壤湿度','20cm土壤温度','20cm土壤湿度','30cm土壤湿度','光合有效辐射','二氧化碳浓度','营养液EC值','营养液pH值','灌溉量','分区灌溉','日灌溉','周灌溉','最近灌溉时间','光照强度'],
		 labelList2:['空气温度','空气湿度','10cm土壤温度','10cm土壤湿度','20cm土壤温度','20cm土壤湿度','40cm土壤温度','40cm土壤湿度','60cm土壤温度','60cm土壤湿度','80cm土壤温度','80cm土壤湿度','风速','风向','光合有效辐射','雨量','大气压','噪声','PM 2.5','PM 10','光照强度'],
		 // realObj:this.realObj1,
		 realObj:{},
		 realObj1:{},
		 realObj2:{},
		 timeId:[],//定时器集合
		 xAxisName: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'] ,// x轴名称
		 data1:[3,4,4,6,8,6,4,4,6,5,8,7],//灌溉次数
		 data2:[10,15,15,19,19,14,14,10,16,11,12,9],//施肥次数
		 warnList:[],
		 landId:1
			 
	}
  },
  mounted () {
  		 // 获取chart盒子的宽度
  		 this.fullWidth = `${document.querySelector('#myChart_a_optimization').clientWidth}`// 默认值
  		 const that = this
  		 window.onresize = () => {
  		   return (() => {
  		     // window.fullWidth = document.documentElement.clientWidth
  		     window.fullWidth = document.querySelector('#myChart_a_optimization').clientWidth
  		     that.fullWidth = window.fullWidth
  		   })()
  		 }
		this.getRealData (1)
		this.nyDeviceList(1)
		this.getIrrigationRegulation(1)
		this.getIrrigationMonitor(1)
		this.warningList(1)
  },
  destroyed() {
  		  console.log('destroyed')
  		  console.log(this.timeId)
  		  // 清空该页面中所有的的定时器
  		  for(let i=0;i<this.timeId.length;i++){
  			   clearInterval(this.timeId[i])
  		  }
		  // 销毁图表实列，释放echarts资源
		  echarts.dispose(this.myChart);
		  this.myChart = null
  },
  methods:{
	// getProfile () {
	//   // 获取表格数据
	//   const obj = {
	//     type: 'Vehicle/vehiclelog',
	//     param: { page: 1, limit: this.paginations.page_size }
	//   }
	//   console.log(obj)
	//   // 发送websocket请求
	//   sendMessageWebsocket(obj, this.wsChange, this.wsError)
	// },
	// wsError () {
	//   // 比如取消页面的loading
	//   console.log('No!')
	// },
	// // ws连接成功，后台返回的ws数据，组件要拿数据渲染页面等操作
	// wsChange (res) {
	//   console.log(' ws连接成功，后台返回的ws数据')
	//   console.log(res)
	//   if (res.type === 'Vehicle/vehiclelog') {
	// 	  console.log('获取到日志')
	//       // 这里写拿到数据后的业务代码
	//       this.tableData = res.data.list
	//       // this.allTableData = res.data.data
	//       // this.filterTableData = res.data.data
	//       this.paginations.total = res.data.count
	//       // this.connectNumber = res.data.count
	//       // 设置分页数据
	//       // this.setPaginations()
	//   }
	// },
	getRealData (id) {
	  const data = {
		  yard_id:id
	  }
	  // 请求数据
	  realtimeDeviceMonitor(data)
	    .then((res) => {
		  console.log("获取实时数据")
	      console.log(res)
		  console.log(res.data)
		  if(res.code===200){
			  this.realObj=res.data
			 
		  }
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getLandList(type){
		console.log("获取场地")
		// 获取表格数据
		const data = {
		 type:type
		}
		// 请求数据
		getYardList(data)
		  .then((res) => {
				  console.log(res)
				  console.log(res.data)
				  // this.options2=res.data
				  if(type===''){
					  this.allOptions=res.data
				  }else{
					 this.options2=res.data 
				  }
		  })
		  .catch((err) => {
		    console.log(err)
		  })
	},
	// 获取地块对应的设备
	nyDeviceList (id) {
	  const data = {
		  yard_id:id
	  }
	  // 请求数据
	  getNyDeviceList(data)
	    .then((res) => {
		  console.log("获取设备状态")
	      console.log(res)
		  console.log(res.data)
		  this.sensorList=res.data
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 灌溉调控
	getIrrigationRegulation (id) {
	  const data = {
		  yard_id:id
	  }
	  // 请求数据
	  irrigation_Regulation(data)
	    .then((res) => {
		  console.log("灌溉调控")
		  console.log(res.data)
		  this.ggList=res.data
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 灌溉监测
	getIrrigationMonitor (id) {
	  const data = {
		  yard_id:id
	  }
	  // 请求数据
	  irrigation_monitor(data)
	    .then((res) => {
		  console.log("灌溉监测")
		  console.log(res.data)
		 this.data1=res.data.gg
		 this.data2=res.data.sf
		 this.$nextTick(function () {
		   this.drawLine();
		  })
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 预警信息
	warningList(id) {
	  const data = {
		  yard_id:id
	  }
	  // 请求数据
	  getWarningList(data)
	    .then((res) => {
		  console.log("预警信息")
		  console.log(res.data)
		  this.warnList=res.data
		
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 选择框事件
	handleChange(value) {
	    console.log(value);
		// //选择的时大田时用labelList2，realObj2，legendList2
		this.labelList=value[0]=='3'?this.labelList2:this.labelList1
		this.realObj=value[0]=='3'?this.realObj2:this.realObj1
		this.legendList=value[0]=='3'?this.legendList2:this.legendList1
		console.log(this.legendList)
		this.$forceUpdate()
	},
	// 场地种类选择
	typeChange(value){
		console.log(value)
		this.landType=value
		this.getLandList(value)
	},
	landChange(e){
		console.log(e)
		this.landId=e
		// //选择的时大田时用labelList2，realObj2，legendList2
		this.labelList=this.landType=='3'?this.labelList2:this.labelList1
		this.realObj=this.landType=='3'?this.realObj2:this.realObj1
		this.legendList=this.landType=='3'?this.legendList2:this.legendList1
		console.log(this.legendList)
		this.$forceUpdate()
		// this.nyDeviceList (e)
		// this.agriculturalLandMonitor (e)
		this.getRealData (e)
		this.nyDeviceList (e)
		this.getIrrigationRegulation(e)
		this.getIrrigationMonitor(e)
		this.warningList(e)
	},
	// 返回
	back(){
		this.$router.go(-1)
	},
	// 设备查看详情
	check(item){
		this.$router.push({
			path:'/equipmentDetail',
			query:{
				id: item.id
			}	  
		})
	},
	// 设备远程控制
	control(item){
		this.$message({
		    message: '抱歉！目前无法连接到设备',
		    type: 'warning'      
		});
	},
	// 灌溉与施肥次数检测
	drawLine () {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart = echarts.init(document.getElementById('myChart_a_optimization'));
			           this.myChart.resize({
			             width: this.$refs.chart.clientWidth,
			             height: this.$refs.chart.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
						   ////动画效果
						   animationDuration: 10000,
						   color: ['#ED9845','#A5EEEB',],
						   title: {
						        text: '今年各月份灌溉与施肥次数统计',
						    	textStyle:{
						    		color: '#A5EEEB',
									fontWeight:400,
									fontSize:12
						    	},
						    },
							legend: {
							  data: ['灌溉次数', '施肥次数'],
							  textStyle:{
							  	  color: '#FFFFFF'
							  },
							  // orient: 'vertical',  //垂直显示
							  y: '10',    //延Y轴居中
							  x: '260' ,//居右显示
							  itemWidth:30,//图例的宽度
							  itemHeight:2,//图例的高度
							},
							toolbox: {
							  feature: {
								magicType: { type: ['line', 'bar'] },
							    saveAsImage: {
									show:true,
							        backgroundColor :'balck',
									pixelRatio: 3
								}
							  },
							},
						   grid: {
						       left: '1%',
						       right: '1%',
						       bottom: '5%',
						       containLabel: true
						   },
						   
						   tooltip: {
						       show: "true",
						       trigger: 'axis',
						       axisPointer: { // 坐标轴指示器，坐标轴触发有效
						           type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						       },
							   backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
							   borderColor:'gray',
							   textStyle:{
							   	align:'left',///标签内容左对齐
							   	color: '#FFFFFF'
							   },
						   },
						   yAxis: {
						       name:'次数',
						       nameTextStyle: {
						           fontSize: 12,
						           fontFamily: 'Microsoft YaHei',
						           color: '#fff'
						       },
						       type: 'value',
						       axisTick: {
						           show: false
						       },
						       axisLine: {
						           show: true,
						           lineStyle: {
						               color: '#535A6B',
						           },
						       },
						       splitLine: {
						           show: false,
						           lineStyle: {
						               color: '#FFFFFF',
						           }
						       },
						   	axisLabel: {
						   	    fontSize: 12,
						   	    fontFamily: 'Microsoft YaHei',
						   	    color: "#FFFFFF",
						   	},
						   },
						   xAxis: [
						       {
						           type: 'category',
						           axisTick: {
						               show: false
						           },
						           axisLine: {
						               show: true,
						               lineStyle: {
						                   color: '#535A6B',
						               }
						           },
						           axisLabel: {
						           fontSize: 12,
						           fontFamily: 'Microsoft YaHei',
						           color: "#FFFFFF",
						           // interval: 0,
						           // rotate: 40,
						           // margin: 10,
						           
						       },
						           data: this.xAxisName
						       }
						   
						   ],
						   series: [
						       {
						           name: '灌溉次数',
						           type: 'bar',
						           itemStyle: {
						               normal: {
						                   show: true,
						                   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						                       offset: 0,
						                       color: '#D76547'
						                   }, {
						                       offset: 0.5,
						                       color: '#D78C47'
						                   },{
						                       offset: 1,
						                       color: '#DAC345'
						                   }]),
						                   barBorderRadius: 50,
						                   borderWidth: 0,
						                   borderColor: '#333',
						               }
						           },
						           label: {
						               normal: {
						                   show: true,
						                   position: 'top',
						                   textStyle: {
						                       color: '#fff'
						                   }
						               }
						           },
						           barWidth: '20%',
						           data: this.data1,
						       },
							   {
							       name: '施肥次数',
							       type: 'bar',
							       itemStyle: {
							           normal: {
							               show: true,
							               color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
							                   offset: 0,
							                   color: 'rgba(165,238,235,0.2)'
							               }, {
							                   offset: 1,
							                   color: 'rgba(165,238,235,1)'
							               }]),
							               barBorderRadius: 50,
							               borderWidth: 0,
							               borderColor: '#333',
							           }
							       },
							       label: {
							           normal: {
							               show: true,
							               position: 'top',
							               textStyle: {
							                   color: '#fff'
							               }
							           }
							       },
							       barWidth: '20%',
							       data: this.data2,
							   }
						   
						   ]
						   					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart.setOption(option);
					   
					   //动态循环显示数据节点
					   this.dataLableChange(this.myChart,option,1)
	},
	// 动态循环显示数据节点方法
	dataLableChange(myChart,option,i){
			   // console.log('shenme')
			   //动态循环显示数据节点
			   var app = {
			       currentIndex: -1,
			   };
			   this.timeId[i]=setInterval(function() {
			   	   // console.log('start'+i)
			       var dataLen = option.series[0].data.length;
			       // 取消之前高亮的图形
			       myChart.dispatchAction({
			           type: 'downplay',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			       app.currentIndex = (app.currentIndex + 1) % dataLen;
			   
			       // 高亮当前图形
			       myChart.dispatchAction({
			           type: 'highlight',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex,
			       });
			   
			       // 显示 tooltip
			       myChart.dispatchAction({
			           type: 'showTip',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			   
			   }, 5000); 
			   
	},
	// 纵坐标的值竖着显示的实现方法
	Ystyle (value) {
	  // console.log(value)
	  if (value) {
	    // 如果传入的值不为空的时候把y轴的文字改为竖版显示
	    const str = value.split('')
	    return str.join('\n')
	  }
	},
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D + h + m +s
	},
  },
  // 监听屏幕变化
  watch: {
    // 如果 `fullHeight ` 发生改变，这个函数就会运行
    fullHeight: function (val) {
      if (!this.timer) {
        this.fullWidth = val
        this.drawLine()
        console.log(val)
        this.timer = true
        const that = this
        setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
          that.timer = false
        }, 100)
      }
    },
	legendList: function (val) {
		console.log('legendList')
      if (!this.timer) {
        this.legendList = val
        this.drawLine()
        console.log(val)
        this.timer = true
        const that = this
        setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
          that.timer = false
        }, 100)
      }
    },
  }
}
</script>

<style scoped="scoped">
.agricultural_optimization {
    width: 100%;
    height: 100%;
	padding: 0 30px 30px 30px;
	box-sizing: border-box;
  }
  .land_head{
	  width: 100%;
	  height: 78px;
	  margin-bottom: 30px;
	  display: flex;
	  align-items:flex-end;
	  justify-content: start;
	  position: relative;
  }
  .select{
	  /* width: 553px; */
	  height: 48px;
	  /* background-color: #1CCA81; */
  }
  .land_title{
	  width: 554px;
	  height: 78px;
	  line-height: 78px;
	  font-size: 28px;
	  font-family: PingFang SC;
	  /* font-weight: bold; */
	  color: #FFFFFF;
	  margin-left: 100px;
	  letter-spacing: 2px;
	  background: url(../../assets/title_bg.png) no-repeat center;
	  background-size: contain;
  }
  .back{
	  position: absolute;
	  right: 0;
	  bottom: 0;
	  width: 135px;
	  height: 48px;
	  line-height: 48px;
	  font-size: 14px;
	  font-family: PingFang SC;
	  letter-spacing: 2px;
	  /* font-weight: bold; */
	  color: #FFFFFF;
	  /* background: #191E1F; */
	  background: url(../../assets/back_bg.png) no-repeat center;
	  background-size: cover;
	  border: 1px solid #4B5052;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  cursor: pointer;
  }
  .back i{
	  font-size: 18px;
  }
  .land_middle{
	  width: 100%;
	 /* height: 391px; */
	  height:calc(40% - 15px);
	  margin-bottom: 30px;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
  }
  .land_pic{
	  /* width: 553px; */
	  width: 30%;
	  height: 100%;
  }
  .land_pic img{
	  width: 100%;
	  height: 100%;
  }
  .video{
	  width: calc(39% - 20px);
	  height: 100%;
	  padding: 0 20px;
	  background-color:  rgba(10,23,56,0.7);
  }
  .sensor{
	  /* width: 552px; */
	  width:calc(28% - 20px) ;
	  /* height: 390px; */
	   height: 100%;
	  background-color:  rgba(10,23,56,0.7);
	  padding: 0 20px;
	  box-sizing: border-box;
	  /* border: 1px solid #36CFC9; */
  }
  .title{
  	  width: 100%;
  	  height: 42px;
  	  display: flex;
  	  align-items: flex-end;
  	  aljustify-content: start;
  	  text-align: left;
	  margin-top: 0;
  }
  .t_l{
  	  width: 33px;
  	  height: 34px;
  	  background: url(../../assets/jiankong/quan_o.png);
   	  background-size: contain;
  	  margin-right: 14px;
  }
  .t_r{
  	  width: calc(100% - 47px);
  	  font-size: 16px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #BFF2F0;
  	  height: 42px;
  	  line-height: 42px;
  }
  .line{
  	  width: 100%;
  	  height: 1px;
  	  background: #36CFC9;
  }
  .sensor_list{
	  /* width: 508px; */
	  width: 100%;
	 /* height: 324px; */
	  height: calc(100% - 65px);
	  overflow: auto;
	  margin-top: 10px;
  }
  .sensor_item{
	  /* width: 500px; */
	  width: calc(100% - 5px);
	  height: 40px;
	  background-color: rgba(11,19,38,0.5);
	  margin-bottom: 8px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  color: #FFFFFF;
	  font-size: 12px;
	  padding:0 10px;
	  box-sizing: border-box;
  }
  .sensor_item div:first-child{
	  width: 160px;
	  text-align: left;
  }
  .sensor_item div{
  	  width: 80px;
  	  text-align: left;
  }
  .check{
	  width: 60px!important;
	  height: 24px;
	  line-height: 24px;
	  text-align: center!important;
	  background: rgba(54, 207, 201, 0.2);
	  border: 1px solid #36CFC9;
	  color: #36CFC9;
	  cursor: pointer;
	  white-space: nowrap;
	  text-overflow: ellipsis;
	  overflow: hidden;
	  word-break: break-all;
  }
  .blue{
	  color:#36CFC9 ;
  }
  .yellow{
  	  color:#ED9845 ;
  }
  .yellow2{
  	  color:#ED9845 ;
	  background: rgba(237, 152, 69, 0.2);
	  border: 1px solid #ED9845;
	  margin-left: 10px;
	  font-size: 10px;
	  width: 90px!important;
  }
  
  .land_bottom{
	 width: 100%;
	 /* height: 445px; */
	 height:calc(60% - 120px);
	 display: flex;
	 justify-content: space-between;
	/* background-color: yellow; */
  }
  .real_time{
	 /* width: 531px; */
	  width: 30%;
	  /* height: 445px; */
	  height: 100%;
	  background-color:  rgba(10,23,56,0.7);
	  padding: 0 20px;
	  box-sizing: border-box;
	  overflow: auto;
  }
  .real_list{
	  width: 100%;
	  height: calc(100% - 42px);
	  padding-top: 25px;
	  box-sizing: border-box;
	  /* background-color: red; */
  }
  .data_item{
  	  float: left;
  	  /* margin-right: 40px; */
	  margin-right: 10%;
  	  font-size: 14px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #BFF2F0;
  	  margin-bottom: 4%;
  	  height: 16px;
  	  display: flex;
  	  align-items: center;
  }
  .data_item:nth-child(2n){
  	  margin-right: 0;
  }
  .data_item div{
  	  display: inline-block;
  	  width: 100px;
  	  text-align:justify;
  	  height: 16px;
  	  line-height: 16px;
  	  
  }
  .data_item div i{
    display:inline-block;
  	height: 1px;
    width:100%;
  }
  .data_number{
  	  display: inline-block;
  	  min-width: 70px;
  	  margin-left: 10px;
  	  font-size: 15px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #FFFFFF;
  }
  .sensor2{
	width:calc(28% - 20px);
	 height: 100%;
	background-color:  rgba(10,23,56,0.7);
	padding: 0 20px;
	box-sizing: border-box;
	/* border: 1px solid #36CFC9; */  
  }
  .ggsb{
	 /* width: 868px; */
	  width: calc(22% - 30px);
	  /* height: 446px; */
	  height: 100%;
	  padding: 0 10px 0 20px;
	  box-sizing: border-box;
	  background-color:  rgba(10,23,56,0.7);
  }
  .ggsb .check{
	  width: 100px;
	  font-size: 10px;
  }
  .ggsb .sensor_item div:first-child{
	  width: 80px;
	  /* background-color: red; */
  }
  .ggsb .sensor_item div:nth-child(2){
  	  width:100px;
 
  }
  #myChart_a_optimization{
     width: 100%;
     height:calc(100% - 70px);
	 margin-top: 20px;
   }
  .ny_data{
	  /* width: 397px; */
	 width: calc(20% - 20px);
	  /* height: 446px; */
	  height: 100%;
	  padding: 0 20px;
	  box-sizing: border-box;
	  background-color:  rgba(10,23,56,0.7);
	  /* border: 1px solid #36CFC9; */
  }
  .ny_list{
	  width: 100%;
	  height: calc(100% - 80px);
	  margin-top: 20px;
	  overflow: auto;
	  /* background-color: #1CCA81; */
  }
 
  .warn_item{
  	  width: 100%;
  	  /* height: 16px; */
  	  font-size: 12px;
  	  font-family: PingFang SC;
  	  color: #BFF2F0;
  	  margin-bottom: 5%;
  	  display: flex;
  	  justify-content: start;
  	  align-items: baseline;
	  overflow: auto;
  }
  .quan{
  	  display: inline-block;
  	  width: 6px;
  	  height: 6px;
  	  background-color:#ED9845;
  	  margin-right: 10px;
  }
  .red{
  	  display: inline-block;
  	  width: 6px;
  	  height: 6px;
  	  margin-right: 10px;
  	  background-color: #CF3636!important;
  }
 .warn_item span:nth-child(2){
	  display: inline-block;
	  text-align: left;
	 /* width: 150px; */
  }
 
  
/deep/.el-select{
 	  margin-right: 30px;
 }
 /deep/.el-select .el-input__inner{
 	  height: 48px;
 	  width: 150px;
 	  background-color: rgba(11,19,38,0.5);
 	  border: 1px solid #36CFC9;
 	  color: #FFFFFF !important;
 }
 /deep/.el-select .el-input.is-focus .el-input__inner{
 	  border: 1px solid #36CFC9;
 }
 /deep/.el-input__inner{
 	  height: 48px;
 	  width: 200px;
 	  background-color: rgba(11,19,38,0.5);
 	  border: 1px solid #36CFC9;
 	  color: #FFFFFF !important;
 }
 /deep/.el-input__suffix{
 	  color: #FFFFFF !important;
 } 
  
  
  /deep/.el-cascader .el-input .el-input__inner{
	  height: 48px;
	  width: 553px;
	  background-color: rgba(11,19,38,0.5);
	  border: 1px solid #36CFC9;
	  color: #FFFFFF !important;
  }
  .none{
  	color: #CCCCCC;
  	font-size: 20px;
  	position: relative;
  	top:50%;
  	transform: translateY(-50%);
  }
 
</style>
<style>
/* // 级联选择器样式 */
.train-tree {
    background-color: rgba(11,19,38);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
   /* font-weight: 400; */
    color: #fff!important;
    line-height: 20px;
    border: none;
    }
.train-tree .el-cascader-menu{
	color: #FFFFFF;
}
.train-tree .el-cascader-node:hover{
	background-color: rgba(0,0,0,1);
}
.train-tree .el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{
	color: #36CFC9;
}
</style>


